.nmThanks {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #D9D9D9;
}

.nmThanks_conntainer {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding: 35px 30px;
    border-radius: 20px;
    background: #FFFFFF;
    margin: 50px 20px;
    align-items: center;
}

.nmThanks_check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    background-color: #E6F3EE;
    border-radius: 50%;
    margin-bottom: 25px;
}

.nmThanks_checked {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: #B0D9C9;
    border-radius: 50%;
}

.nmThanks_conntainer h1 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin: 10px 0;
    color: #404242;
}

.nmThanks_conntainer p {
    font-size: 14px;
    font-weight: 600;
    text-align: center;    
    max-width: 475px;
    margin-bottom: 30px;
}

.nmThanks_conntainer button {
    display: flex;
    width: 180px;
    height: 48px;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    background: #018652;
    border: none;
    margin: 15px 0;

    font-size: 14px;
    font-weight: 600;

    color: #FFFFFF;
    cursor: pointer;
}

.nmThanks_conntainer button:hover {
    background: #0d9661;
    transition: 300ms ease-in-out 0s;
}

.nmThanks_links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.nmThanks_icons {
    display: flex;
    padding: 15px;
    margin: 0 10px;
    border: 1px solid #018652;
    border-radius: 50px;
    color: #018652;
    cursor: pointer;
}

.nmThanks_icons:hover {
    background-color: #2ac489;
    transition: 300ms ease-in-out 0s;
    color: #ffffff;
}