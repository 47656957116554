.nmNavbar {
    display: flex;
    justify-content: center;
    width: auto;
}

.nmNavbar_container {
    display: flex;
    width: 100%;
    max-width: 1440px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
}

.nmNavbar img {
    cursor: pointer;
}

.nmNavbar button {
    padding: 10px 20px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #FFFFFF;
    background-color: #018652;
    border: none;
    cursor: pointer;
}

.nmNavbar button:hover {
    background: #0d9661;
    transition: 300ms ease-in-out 0s;
}