.nmJoin {
    display: flex;
    background: url(../../img/Mask\ group.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #014A2D;
    width: auto;
    align-items: center;
    justify-content: center;
}

.nmJoin_container {
    display: flex;
    width: 100%;
    max-width: 1440px;
    padding: 80px;
}

.nmJoin_text {
    display: flex;
    flex-direction: column;
    align-items: flex;
    justify-content: center;
    margin-right: 20px;
    padding-right: 20px;
    flex: 1;
}

.nmJoin_text h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: #EDEDED;
    margin-bottom: 15px;
}

.nmJoin_text p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #EDEDED;
    margin-bottom: 20px;
}

.nmJoin_text button {
    padding: 15px 20px;
    border-radius: 10px;
    border: 2px solid #FFFFFF;
    background: transparent;
    color: #EDEDED;

    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.nmJoin_image {
    display: flex;
    flex: 1;
}

.nmJoin_image img {
    width: 100%;
}


@media screen and (max-width: 1050px) {
    .nmJoin_image {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 750px) {
    .nmJoin_container {
        padding: 60px 20px;
        align-items: center;
        justify-content: center;
    }

    .nmJoin_text {
        align-items: center;
        justify-content: center;
    }

    .nmJoin_text h1 {
        font-size: 34px;
        line-height: 42px;
        text-align: center;
    }
    
    .nmJoin_text p {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 50px;
    }
}