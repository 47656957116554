.nmSell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #D9D9D9;
}

.nmSell_container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding: 35px 30px;
    border-radius: 20px;
    background: #FFFFFF;
    margin: 20px;
}

.nmSell_progress {
    display: flex;
    margin-bottom: 40px;
}

.nmSell_progress span {
    flex: 1;
    height: 8px;
    border-radius: 10px;
    background: #018652;
}

.nmSell_progress span:nth-child(2) {
    margin: 0 10px;
}

.nmSell_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}

.nmSell_header h1 {
    font-size: 24px;
    font-weight: 600;
    text-align: left;    
    color: #404242;
    margin-bottom: 10px;
}

.nmSell_header p {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;    
    color: #6B6D6D;
    max-width: 90%;
}

.nmSell_categories {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.nmSell_wrapper {
    margin: 5px 10px 5px 0;
}

.nmSell_wrapper label {
    cursor: pointer;   
}

.nmSell_wrapper label input {
    display: none;
}

.nmSell_wrapper label span {
    position: relative;
    display: inline-block;
    background: transparent;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #838584;
    overflow: hidden;
    
    font-size: 14px;
    font-weight: 600;
    color: #838584;
}

.nmSell_wrapper label span:hover {
    background: #bbf5de;
    transition: 300ms ease-in-out 0s;
}

.nmSell_wrapper label span:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
}

.nmSell_wrapper label input[type='checkbox']:checked ~ span {
    background: #018652;
    color: #FFFFFF;
    transition: 300ms ease-in-out 0s;
}

.nmSell_nListed {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    padding: 0px 20px;
    margin-bottom: 20px;
    
    border-radius: 10px;
    border: 1px solid #AAABAB;
    width: 100%;
}

.nmSell_categories p {
    font-size: 14px;
    font-weight: 600;
    color: #6B6D6D;
    margin-top: 20px;
}

.nmSell_nListed input { 
    width: 100%;
    padding: 20px 0px;
    border: none;
    outline: none;

    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #AAABAB;
}

.nmSell_nListed input::placeholder {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #C6C7C6;
}

.nmSell_container button {
    display: flex;
    width: 180px;
    height: 48px;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    background: #018652;
    border: none;
    margin: 15px 0;

    font-size: 14px;
    font-weight: 600;

    color: #FFFFFF;
    cursor: pointer;
}

.nmSell_container button:hover {
    background: #0d9661;
    transition: 300ms ease-in-out 0s;
}

@media screen and (max-width: 575px) {   
    .nmSell {
        height: auto;
    }

    .nmSell_progress {
        margin-bottom: 30px;
    }

    .nmSell_header h1 {
        font-size: 22px;
    }

    .nmSell_header p {
        font-size: 12px;
    }

    .nmSell_header {
        margin-bottom: 15px;
    }

    .nmSell_wrapper {
        margin: 5px 10px 5px 0;
    }

    .nmSell_wrapper label span {
        padding: 8px 15px;
        
        font-size: 12px;
    }

    .nmSell_nListed {
        margin-top: 15px;
        padding: 0px 15px;
        margin-bottom: 15px;
    }

    .nmSell_nListed input {
        padding: 15px 0px;
    
        font-size: 12px;
    }

    .nmSell_nListed input::placeholder {
        font-size: 12px;
    }
}