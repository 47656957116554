.nmHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 80px 80px 80px;
    background: #E6F3EE;
    width: auto;
}

.nmHeader p {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
}

.trailblazers {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid gray;
    padding: 10px;
    background-color: #FFFFFF;
    margin-bottom: 40px;
}

.trailblazers p {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #404242;
}

.nmHeader h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    text-align: center;
    max-width: 800px;
    color: #404242;
    margin-bottom: 30px;
}

.desc {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    max-width: 900px;
    margin-bottom: 30px;
}

.nmHeader button {
    padding: 10px 20px;
    border-radius: 5px;
    height: 48px;
    background: #018652;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
}

.nmHeader button:hover {
    background: #0d9661;
    transition: 300ms ease-in-out 0s;
}

@media screen and (max-width: 750px) {
    .nmHeader {
        padding: 100px 40px 40px 40px;
    }

    .trailblazers {
        margin-bottom: 30px;
    }

    .trailblazers p {
        font-size: 18px;
    }

    .nmHeader h2 {
        font-size: 44px;
        line-height: 50px;
        margin-bottom: 20px;
    }

    .desc {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 550px) {
    .nmHeader {
        padding: 80px 20px 40px 20px;
    }

    .trailblazers {
        margin-bottom: 20px;
    }

    .trailblazers p {
        font-size: 14px;
    }

    .nmHeader h2 {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 15px;
    }

    .desc {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 15px;
    }
}