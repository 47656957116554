.nmBuySell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #D9D9D9;
}

.nmBuySell_container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding: 25px 20px;
    border-radius: 20px;
    background: #FFFFFF;
    margin: 20px;
}

.nmBuySell_progress {
    display: flex;
    margin-bottom: 40px;
}

.nmBuySell_progress span {
    flex: 1;
    height: 8px;
    border-radius: 10px;
    background: #D9D9D9;
}

.nmBuySell_progress span:nth-child(1) {
    background: #018652;
}

.nmBuySell_progress span:nth-child(2) {
    margin: 0 10px;
}

.nmBuySell_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 450px;
    margin-bottom: 20px;
}

.nmBuySell_header h1 {
    font-size: 24px;
    font-weight: 600;
    text-align: left;    
    color: #404242;
    margin-bottom: 10px;
}

.nmBuySell_header p {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;    
    color: #6B6D6D;
}

.nmBuySell_radio {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1.5px solid #349E75;
    border-radius: 10px;
    margin: 15px 0;
}

.nmBuySell_select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.nmBuySell_custom {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    text-align: left;    
    color: #000000;
    display: inline-block;
    position: relative;
}

.nmBuySell_custom input[type='radio'] {
    display: none;
}

.nmBuySell_custom span {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 2px solid #018652;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}

.nmBuySell_custom span::after {
    content: '';
    height: 18px;
    width: 18px;
    background: #018652;
    display: flex;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    transition: 300ms ease-in-out 0s;
}

.nmBuySell_custom input[type='radio']:checked ~ span:after {
    transform: translate(-50%, -50%) scale(1);
}

.nmBuySell_radio p {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;    
    color: #6B6D6D;
}


.nmBuySell_container button {
    display: flex;
    width: 180px;
    height: 48px;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    background: #018652;
    border: none;
    margin: 15px 0;

    font-size: 14px;
    font-weight: 600;

    color: #FFFFFF;
    cursor: pointer;
}

.nmBuySell_container button:hover {
    background: #0d9661;
    transition: 300ms ease-in-out 0s;
}

@media screen and (max-width: 575px) {   
    .nmBuySell_progress {
        margin-bottom: 30px;
    }

    .nmBuySell_header h1 {
        font-size: 22px;
    }

    .nmBuySell_header p {
        font-size: 12px;
    }

    .nmBuySell_header {
        margin-bottom: 15px;
    }

    .nmBuySell_radio {
        padding: 20px;
        margin: 10px 0;
    }

    .nmBuySell_custom {
        font-size: 18px;
    }

    .nmBuySell_custom span {
        height: 20px;
        width: 20px;
    }

    .nmBuySell_custom span::after {
        height: 14px;
        width: 14px;
    }

    .nmBuySell_radio p {
        font-size: 12px;
    }
}